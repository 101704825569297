import React from 'react';
import IconAssetAsian from '../../images/icons/asset-asian.svg';
import IconStartupAward from '../../images/icons/startup-award.svg';
import IconFintechAward from '../../images/icons/fintech-award.svg';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';
import AwardImg from '../../images/icons/payment-award.svg';
import PropTypes from 'prop-types';
import '../../styles/components/footer-awards.scss';
const FooterAwards = ({ className }) => {
  const image1 = {
    url: IconAssetAsian,
    width: 56,
    height: 56
  };
  const image2 = {
    url: IconStartupAward,
    width: 56,
    height: 56
  };
  const image3 = {
    url: IconFintechAward,
    width: 56,
    height: 56
  };
  const image4 = {
    url: AwardImg,
    width: 110,
    height: 40
  };
  return (
    <div
      className={`flex flex-wrap relative items-center xl:justify-between justify-center mt-10 xl:gap-0 gap-8 mx-auto ${className}`}
    >
      <div className="dbs-certificate-footer flex md:flex-row justify-center items-center xl:gap-1 gap-2 ">
        <GatsbyImageWrapper image={image3} className="w-[110px] h-10" objectFit="contain" alt="IconFintechAward" />
        <div className="flex flex-col px-auto md:px-2 ">
          <p className=" text-bold text-[10px] relative z-10 md:text-left mx-auto text-center">Statrys Limited</p>
          <p className="whitespace-pre-line relative leading-3 text-[10px] z-10 md:text-left text-center">
            Fintech Payments 2024
          </p>
        </div>
      </div>
      <div className="top-certificate-footer flex md:flex-row justify-center items-center xl:gap-0 gap-2">
        {' '}
        <GatsbyImageWrapper image={image2} className="h-[40px] w-[72px]" alt="startup-award" />
        <div className="flex flex-col px-auto md:px-1 ">
          <p className=" text-bold text-[10px] relative z-10 md:text-left text-center" title="">
            FINTECH HONG KONG
          </p>
          <p className="whitespace-pre-line relative leading-3 text-[10px] z-10">{`Fintechnews.hk`}</p>
        </div>
      </div>
      <div className="dbs-certificate-footer flex md:flex-row md:justify-start justify-center items-center xl:gap-0 gap-2 ">
        <GatsbyImageWrapper image={image4} className="w-[110px] h-10" objectFit="contain" alt="IconFintechAward" />
        <div className="flex flex-col px-auto md:px-2 ">
          <p className=" text-bold text-[10px] relative z-10 md:text-left text-center">Statrys Limited</p>
          <p className="whitespace-pre-line relative leading-3 text-[10px] z-10 md:text-left text-center">
            {`SME Payment Solutions of \nthe Year 2023 - Hong Kong`}
          </p>
        </div>
      </div>
      <div className="dbs-certificate-footer flex md:flex-row justify-center items-center xl:gap-1 gap-2 ">
        <GatsbyImageWrapper image={image1} className="h-10 w-10" alt="IconPaymentAward" />
        <div className="flex flex-col px-auto md:px-2 ">
          <p className=" text-bold text-[10px] relative z-10 md:text-left text-center">Statrys Limited</p>
          <p className="whitespace-pre-line relative leading-3 text-[10px] z-10 md:text-left text-center">
            {`Best Payments and Collections \nSolution Hong Kong 2022`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FooterAwards;

FooterAwards.propTypes = {
  onlyDbs: PropTypes.bool,
  className: PropTypes.string
};
