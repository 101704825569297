export const API_CODE = {
  STATUS_200: 200,
  STATUS_201: 201,
  STATUS_204: 204
};
export const API_ERROR_CODES = {
  STATUS_400: 400,
  STATUS_401: 401,
  STATUS_402: 402,
  STATUS_403: 403,
  STATUS_404: 404,
  STATUS_412: 412,
  STATUS_422: 422,
  STATUS_500: 500
};
export const INDUSTRIES_CODE = {
  SUB_CATEGORY: 'OT-OT'
};
export const OWNER_DIRECTOR_TYPES = {
  OWNER: 'OWNER',
  DIRECTOR: 'DIRECTOR',
  OWNER_DIRECTOR: 'OWNER_DIRECTOR',
  NOMINEE_OWNER: 'NOMINEE_OWNER',
  NOMINEE_OWNER_DIRECTOR: 'NOMINEE_OWNER_DIRECTOR'
};
export const APPLICANT_TYPE = {
  THIRD_PARTY: 'thirdParty',
  SOLE_OWNER: 'soleOwner',
  MULTIPLE_OWNER: 'multipleOwner'
};
export const RELATIONSHIP_WITH_COMPANY = {
  OPTIONS: ['Employee', 'Company Secretary', 'Accountant', 'Others'],
  COMPANY_SEC: 'COMPANY_SEC',
  COMPANY_SECRETARY: 'Company Secretary',
  EMPLOYEE: 'Employee',
  ACCOUNTANT: 'Accountant',
  OTHERS: 'Others'
};
export const REGEX = {
  UPPER_CASE_REGEX: /(?=.*[A-Z])/,
  LOWER_CASE_REGEX: /(?=.*[a-z])/,
  DIGIT_CASE_REGEX: /(?=.*\d)/,
  SPECIAL_CASE_REGEX: /(?=.*[-+_!@#$%^&*.,?])/,
  SPACE_REGEX: /(?=.*\s)/,
  EMAIL_REGEX: /^(?=[\w\s-+.])\s*[-+.'\w]+@[-.\w]+\.[-.\w]+\s*$/,
  HUBSPOT_COOKIE_REGEX: '(^|;)\\s*hubspotutk\\s*=\\s*([^;]+)',
  ONLY_STRING_REGEX: /([A-Za-z])$/,
  REPLACE_STRING_REGEX: /[^\d.-]/g,
  REPLACE_SLASH: /\//g,
  EXCEPT_SLASH_AND_QUOTES_REGEX: /^[^/\\'"]+$/,
  FIND_ALL_WHITESPACE: /\s/g,
  CARD_NUMBER_SPACE_REGEX: /\d{4}(?=.)/g,
  WATCH: /watch\?v=/,
  ONLY_ALPHABETS_AND_SPACE_ALLOWED: /^[A-Za-z\s]*$/
};
export const VALIDATE_TOKEN = {
  TYPE: 'SU'
};
export const JURISDICTION = {
  HK: { code: 'HK', name: 'Hong Kong SAR' },
  SG: { code: 'SG', name: 'Singapore' },
  VG: { code: 'VG', name: 'British Virgin Islands' },
  OTHER_JURISDICTION: 'other-jurisdiction'
};
export const COMPANY_REGISTRATION_COUNTRY = {
  HONG_KONG: 'HongKong',
  SINGAPORE: 'Singapore'
};
export const VERIFY_OTP_STATUS = {
  VERIFIED: 'VERIFIED',
  EXPIRED: 'EXPIRED'
};
export const COUNTRY_CODE = {
  ALPHA_2: 'alpha-2'
};
export const COMPANY_STRUCTURE_CARD_TYPE = {
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company',
  THIRD_PARTY: 'ThirdParty'
};
export const COMPANY_STRUCTURE_EDIT_TYPE = {
  EDIT_OWNER_AND_DIRECTOR: 'editOwnerAndDirector',
  EDIT_THIRD_PARTY: 'editThirdParty'
};

export const FILE_UPLOAD_TYPE = {
  PDF: 'application/pdf',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  MSWORD: 'application/msword',
  OFFICE_DOCUMENT: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
};
export const DOCUMENT_UPLOAD_TYPE = {
  BUSINESS_PROFILE: 'BUSINESS_PROFILE',
  PASSPORT: 'PASSPORT',
  PASSPORT_ID: 'PASSPORT_ID'
};
export const DOCUMENT_SOURCE_TYPE = {
  PROSPECT_CLIENT: 'PROSPECT_CLIENT',
  PROSPECT_ONBOARDING_OTHERS_INTERNAL_UPLOAD: 'PROSPECT_ONBOARDING_OTHERS_INTERNAL_UPLOAD',
  PROSPECT_ONBOARDING_KYB_INTERNAL_UPLOAD: 'PROSPECT_ONBOARDING_KYB_INTERNAL_UPLOAD',
  CLIENT_KYB_OTHERS_INTERNAL_UPLOAD: 'CLIENT_KYB_OTHERS_INTERNAL_UPLOAD'
};
export const STAKEHOLDER_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY'
};
export const COMPANY_TYPE = {
  REGISTERED: 'REGISTERED'
};
export const APPLICATION_STAGE_COMPLETE = {
  COMPANY_HIERARCHY: 'COMPANY_HIERARCHY',
  DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD'
};
export const APPLICATION_SUBMISSION_META_QUESTIONS = {
  SOURCE_OF_DISCOVERY: 'SOURCE_OF_DISCOVERY',
  AUTHORIZE_STATRYS: 'AUTHORIZE_STATRYS',
  ACKNOWLEDGE_PROHIBITED_COUNTRIES: 'ACKNOWLEDGE_PROHIBITED_COUNTRIES',
  TERMS_AND_CONDITIONS_AGREEMENT: 'TERMS_AND_CONDITIONS_AGREEMENT'
};
export const MUI_COLORS = {
  CORAL: '#E15741',
  WHITE: '#FFFFFF',
  SILVER_MEDAL: '#D6D6D6',
  SUNSET_ORANGE: '#FF4F42',
  EXTRA_LIGHT_GRAY: '#F9F9F9',
  PURPLE: '#2D0D45',
  DARK_CHARCOAL: '#2F2F2F',
  DARK_GRAY: '#474747',
  GRAY: '#7A7A7A',
  LIGHT_CREAM: '#F9DBD1',
  LIGHT_GREEN: '#E6F2F1',
  LIGHT_YELLOW: '#FFEDDE',
  LIGHT_GRAY: '#F5F1ED',
  DARK_GRAY_MAIN: '#464646',
  GREEN: '#097D75',
  SUNGLOW: '#FFC730',
  BLUE: '#1B6BD3',
  WHATS_APP_GREEN: '#25D365',
  PINK: '#FCEFE8',
  PAPAYA_WHIP: '#FFF3D4',
  DARK_GREEN: '#043734',
  MID_NIGHT: '#212B36',
  DARK_GRAYISH_BLUE: '#919EAB',
  WHITE_SMOKE: '#f5f5f5',
  CHARCOAL: '#333333',
  PANTONE: '#EC9183',
  BLACK: '#000000',
  EERIE_BLACK: '#1B1B1B',
  BROWN: '#6F5511'
};
export const AVATAR_COlORS = ['#2D0D45', '#097D75', '#043734', '#1B6BD3', '#FFC730', '#F9DBD1', '#DAD2BC', '#E2FBFA'];

export const STATUS = {
  FALSE: 'false',
  TRUE: 'true'
};
export const HEAR_ABOUT_STATRYS = {
  AS0: { code: 'AS0', text: 'Found you online' },
  AS1: { code: 'AS1', text: 'Recommended by a friend' },
  AS2: { code: 'AS2', text: 'Recommended by a partner' },
  AS3: { code: 'AS3', text: 'I was in touch with your team' },
  AS4: { code: 'AS4', text: 'I have another account with Statrys' },
  AS5: { code: 'AS5', text: 'Recommended by my Company Secretary' }
};

export const DateFormats = {
  DDMMMYYY: 'DD MMM YYYY'
};

export const APPLICATION_COMPLETION_PERCENTAGE = {
  COMPLETION_0: 0,
  COMPLETION_20: 20,
  COMPLETION_40: 40,
  COMPLETION_60: 60,
  COMPLETION_80: 80
};

export const ERROR_STATUS_CODE = {
  ONB_1038: 'ONB-1038',
  ONB_1008: 'ONB-1008'
};

export const FILE_SIZE = {
  FOUR_MB_IN_BYTES: 4194304
};
export const RESOURCE_TYPE = {
  WHITEPAPER: 'Whitepapers',
  NEWSLETTER: 'Newsletters'
};

export const REQUIRED_LENGTH = {
  OTP_LENGTH: 6,
  PASSWORD_LENGTH: 10,
  ALLOWED_DOCUMENT_LENGTH: 100,
  DESCRITPTION_LENGTH: 49,
  REFERRAL_CODE_LENGTH: 6
};

export const COMPANY_STRUCTURE_STEP_CASE = {
  RELATIONSHIP_WITH_COMPANY_STEP: 'RelationShipWithCompanyStep'
};

export const MFA_OTP_STATUS = {
  VERIFIED: 'VERIFIED',
  EXPIRED: 'EXPIRED'
};

export const BUSINESS_PROFILE_DATA = {
  INDUSTRY: 'Industry',
  SUB_INDUSTRY: 'Sub-industry'
};

export const MFA_NOTIFICATION_STATUS = {
  PENDING_ACTION: 'PENDING_ACTION',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED'
};

export const MFA_CHANNEL_TYPES = {
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION'
};

export const CLIENT_APPLICATION_COUNT = {
  TWO: 2,
  ONE: 1,
  ZERO: 0
};
export const VERIFY_MOBILE_NUMBER_STATUS = {
  INCORRECT: 'INCORRECT'
};

export const RICH_TEXT_TYPE = {
  PURPLE: 'purple',
  GREEN: 'green',
  YELLOW: 'yellow',
  GRAY: 'gray',
  PINK: 'pink',
  SPACER: 'spacer'
};

export const PASSWORD_TYPE = {
  password: 'password',
  confirmPassword: 'confirmPassword'
};

export const SETUP_PASSWORD_TOKEN_VALIDATE_STATE = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  ALREADY_ACCOUNT_EXISTS: 'ALREADY_ACCOUNT_EXISTS'
};

export const AUTH_CODES = {
  AUTH_1037: 'AUTH-1037',
  AUTH_1010: 'AUTH-1010',
  AUTH_1082: 'AUTH-1082',
  AUTH_1085: 'AUTH-1085'
};
export const SITE_URL = 'https://statrys.com/';
export const EXTERNAL_LINKS = {
  APPLE_STORE: 'https://apps.apple.com/us/app/statrys/id1590487745',
  GOOGLE_STORE: 'https://play.google.com/store/apps/details?id=com.statrys.statrys',
  TRUST_PILOT: 'https://www.trustpilot.com/review/statrys.com',
  INVOICE: 'https://invoice.statrys.com/signup',
  INVOICE_COMPANY_SETUP_URL: '/company-set-up',
  GUEST_BLOG: 'https://notionforms.io/forms/write-for-us-1',
  AIRWALLEX_REVIEW: 'https://notionforms.io/forms/airwallex-review',
  COMPANY_CREATION_CALENDAR: 'https://calendly.com/incorporation/discovery-company-creation-call',
  SINGAPORE_COMPANY_CREATION_CALENDAR: 'https://calendly.com/incorporation/discovery-call-company-creation-singapore',
  OCEAN_YOUTUBE_SHORT: 'https://www.youtube.com/embed/rHYkJRSu_pA?autoplay=1',
  BUSINESS_ACCOUNT_YOUTUBE_VIDEO: 'https://www.youtube.com/watch?v=qDKRpHcw20I',
  Talk_TO_US: 'https://calendly.com/statrysbusinessaccount/business-account-discovery-call',
  TALK_TO_FX_EXPERT: 'https://calendly.com/statrysbusinessaccount/fx-expert-call'
};

export const BLOGS_UID = {
  MAIN_BLOG: 'opening-a-business-bank-account-in-hong-kong',
  FEATURED_BLOG: 'setting-up-a-company'
};

export const SHOW_NUMBER_OF_BLOGS = {
  TWELVE: 12,
  SIX: 6,
  EIGHTEEN: 18,
  TEN: 10,
  FIFTEEN: 15,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  NINE: 9,
  EIGHT: 8
};

export const NOT_SHOW_BORDER_OF_BLOG = {
  EIGHT: 8,
  FOUR: 4,
  NINE: 9,
  TWELVE: 12,
  FIVE: 5
};
export const INTERNAL_LINKS = {
  TERMS_AND_CONDITIONS: `${process.env.SITEURL}/terms-and-conditions`,
  STRIPE_SUCCESS_URL: `${process.env.SITEURL}/signup/success`,
  STRIPE_CANCEL_URL: `${process.env.SITEURL}/signup/failure`,
  PRIVACY_POLICY: `${process.env.SITEURL}/terms-and-conditions?=privacy-policy`
};
export const EMAIL_JS = {
  PUBLIC_KEY: 'Ndc-RofFsjZvXX_2O',
  SERVICE_ID: 'service_blnhz0m',
  COMPANY_CREATION_SERVICE_ID: 'noreply_new',
  LEAVE_REVIEW_TEMPLATE_ID: 'template_z2qwwil',
  REGISTER_COMPANY_WAITING_LIST: 'template_l8atqd5',
  HONGKONG_COMPANY_REGISTRATION: 'template_nnfi22d',
  NEW_HONGKONG_COMPANY_REGISTRATION: 'company_registration_new',
  COMPANY_TO_USER: 'company_to_user'
};
export const SCREEN_WIDTH = {
  SM: 768,
  LG: 1155
};
export const SLICE_TYPE = {
  RICH_TEXT: 'rich_text',
  IMAGE: 'image',
  TABLE: 'table',
  PODCAST: 'podcast',
  YOUTUBE_VIDEO: 'youtube_video',
  EDITORS_NOTE: 'editors_note',
  BLOG_CTA: 'blog_cta',
  KEY_TAKEAWAYS: 'key_takeaways',
  DOUBLE_COLUMN: 'double_column',
  QUOTE: 'quote',
  REPORT_SLICE: '_report_slice',
  BLOG_IMAGE_WITH_LINK: 'blog_image_with_link',
  REPORT_KEY_POINTS: 'report_key_points',
  LINK_CTA: 'link_cta',
  HIGHLIGHT_BOX: 'highlight_box',
  CUSTOM_TABLE: 'custom_table',
  HEADING: 'heading_text',
  TOGGLEABLE: 'toggleable_component',
  POLLING: 'polling'
};
export const BLOG_CATEGORIES_TYPE = {
  BANKING_FUNDAMENTALS: 'Banking Fundamentals',
  NEWS: 'News',
  COMPETITOR_REVIEWS: 'Competitor reviews'
};
export const CHAR_CODE = {
  ENTER_KEY_CODE: 13
};

export const SVG_TYPES = {
  STATRYS_LOGO: 'statrysLogo',
  BOOK_OPEN_BOOKMARK: 'bookOpenBookMark',
  EURO_IBAN_ACCOUNT: 'euroIbanAccount',
  CREDIT_CARD: 'creditCard',
  CURRENCY_EURO_DOLLAR_EXCHANGE: 'currencyEuroDollarExchange',
  TAG_DOLLAR: 'tagDollar',
  QUESTION_CIRCLE: 'questionCircle',
  BOOK_LIBRARY: 'bookLibrary',
  BOOK_OPEN: 'bookOpen',
  SINGLE_MAN_MAIL: 'singleManMail',
  TEAM_IDEA: 'teamIdea',
  NEWS_PAPER_READ: 'newsPaperRead',
  BUSINESS_PARTNER: 'businessPartner',
  CONTACT_US: 'contactUs',
  BULB: 'bulb',
  RATING_STARS: 'ratingStars',
  SEND_EMAIL: 'sendEmail',
  CONTACT_PHONE: 'contactPhone',
  PERFORMANCE_GRAPH: 'calculator',
  ACCOUNTING_CALCULATOR: 'stripeCalculator',
  INVOICE_GENERATOR: 'Invoice',
  PAYMENTS_BILLS: 'PAYMENTS_BILLS',
  GET_REWARDED: 'GET_REWARDED',
  WRITE_BLOG: 'WRITE_BLOG'
};

export const UNSPLASH_REFERRAL = {
  WEBSITE_URL: 'https://unsplash.com',
  URL: '?utm_source=Statrys&utm_medium=referral'
};
export const MODAL_TYPE = {
  PROHIBITED_INDUSTRIES: 'PROHIBITED-INDUSTRIES',
  RESTRICTED_COUNTRIES: 'RESTRICTED-COUNTRIES'
};

export const MODAL_TITLE = {
  PROHIBITED_INDUSTRIES: 'Prohibited Industries',
  RESTRICTED_COUNTRIES: 'Restricted Countries'
};
export const SPECIAL_CHARACTER_VALIDATION = {
  FOR_NAMES: /[%<>\\$#!@%^&*()?+'"]/,
  FOR_SPACE: /\s/g,
  FOR_NBSP: /(&nbsp;)*/g,
  FOR_COMMA: /[,.]/g
};

export const REGISTER_COMPANY_WAITING_LIST_STEP = {
  FINAL_STEP: 4
};

export const INVOICE_TYPE = {
  FROM: 'From',
  TO: 'To'
};
export const VERIFY_MFA_OTP_WARNING_MESSAGES = {
  REACTIVATE_WARNING_MESSAGE:
    'To re-activate push notifications for MFA on your mobile app, please login on your phone again and follow the steps.',
  ENABLE_MFA_NOTIFICATION_WARNING_MESSAGE:
    'To enable push notifications for MFA on your mobile app, please login on your phone and follow the steps.',
  LOGIN_THROUGH_APP_WARNING_MESSAGE:
    'Please login on your mobile app and follow the steps in order to continue receiving MFA notifications from the mobile app. '
};

export const MFA_WARNING_TYPES = {
  VALIDATION_KEY_EXPIRED: 'VALIDATION_KEY_EXPIRED'
};

export const PRODUCT_PAGES_URL = {
  COMPANY_SECRETARY_URL: '/reviews/company-secretary-hong-kong',
  PRIVACY_POLICY_URL: '/terms-and-conditions?=privacy-policy',
  HONGKONG_COMPANY_REGISTRATION: '/hong-kong-company-registration'
};

export const BLOG_UID = {
  PAYPAL_BUSINESS_VS_PERSONAL: 'paypal-business-vs-personal',
  PAYPAL_PAYMENT_GUIDE: 'paypal-payment-guide',
  WITHDRAW_MONEY_PAYPAL: 'withdraw-money-paypal',
  ADD_MONEY_PAYPAL: 'add-money-paypal'
};

export const FEES = process.env.COMPANY_CREATION_DISCOUNT
  ? {
      FOR_PAY_PAL: 7740,
      SINGAPORE_FOR_PAY_PAL: 3150,
      COMPANY_CREATION: 774000,
      COMPANY_CREATION_CAPTION: '7,740',
      SINGAPORE_COMPANY_CREATION_CAPTION: '3,150'
    }
  : {
      FOR_PAY_PAL: 8600,
      SINGAPORE_FOR_PAY_PAL: 3500,
      COMPANY_CREATION: 860000,
      COMPANY_CREATION_CAPTION: '8,600',
      SINGAPORE_COMPANY_CREATION_CAPTION: '3,500'
    };
export const HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING = [
  {
    title: 'HKD',
    price: '8,600'
  },
  {
    title: 'EUR',
    price: '1,025'
  },
  {
    title: 'USD',
    price: '1,099'
  }
];
export const SINGAPORE_COMPANY_CREATION_ORIGINAL_PRICING = [
  {
    title: 'SGD',
    price: '3,500'
  },
  {
    title: 'EUR',
    price: '2,390'
  },
  {
    title: 'USD',
    price: '2,630'
  }
];
export const HONG_KONG_COMPANY_CREATION_FEES_DROPDOWN = process.env.COMPANY_CREATION_DISCOUNT
  ? [
      {
        title: 'HKD',
        price: '7,740'
      },
      {
        title: 'EUR',
        price: '909'
      },
      {
        title: 'USD',
        price: '990'
      }
    ]
  : HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING;

export const SINGAPORE_COMPANY_CREATION_FEES_DROPDOWN = process.env.COMPANY_CREATION_DISCOUNT
  ? [
      {
        title: 'SGD',
        price: '3,150'
      },
      {
        title: 'EUR',
        price: '2,151'
      },
      {
        title: 'USD',
        price: '2,343'
      }
    ]
  : SINGAPORE_COMPANY_CREATION_ORIGINAL_PRICING;

export const CARD_ERROR = {
  INVALID_EXPIRY_YEAR: 'invalid_expiry_year',
  INVALID_EXPIRY_MONTH: 'invalid_expiry_month',
  CARD_DECLINED: 'card_declined',
  INVALID_CVC: 'incorrect_cvc',
  INCORRECT_NUMBER: 'incorrect_number'
};
export const QUIZE_STEPS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10
};
export const ACCEPTABEL_INDUSTRIES_CODE_FOR_OTHERJURISDICTION = [
  'WTT-PT',
  'LGT-LG',
  'LGT-WH',
  'LGT-TP',
  'LGT-OT',
  'PMO-VOIP'
];

export const MAX_LENGTH = {
  COMPANY_NAME: 100,
  EMAIL: 100,
  FIRST_NAME: 50,
  LAST_NAME: 50
};

export const TERMS_AND_CONDITIONS_UID = {
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  LIST_PROHIBITED_INDUSTRIES: 'list-prohibited-industries',
  PRIVACY_POLICY: 'privacy-policy',
  LIST_PROHIBITED_COUNTRIES: 'list-prohibited-countries'
};

export const OTHER_COMPANY_REGISTRATION_NAME = {
  SLEEK: 'Sleek',
  OSOME: 'Osome',
  STARTUPREGISTRY: 'StartUpRegistry'
};

export const SIGN_UP_FLOW_CONSTANT = {
  GET_STARTED: 'getStarted'
};

export const SENDGRID_DETAILS = {
  LIST_ID_PAYPAL_CALCULATOR_SIGNUP: '4cff10ad-80a6-40cc-ba03-53c4af866881'
};
export const SESSION_STORAGE_NAME = {
  COUNTRY_FOR_BUSINESS_ACCOUNT_REVIEW: 'countryForBusinessAccountReview'
};

export const POST_TYPE = {
  ARTICLE: 'article',
  BLOG: 'blog',
  SILOS_SECTION: 'silos-section'
};

export const PAYMENT_METHOD = {
  CREDIT_CARD: 'CREDIT_CARD',
  PAY_PAL: 'PAY_PAL'
};

export const SIGN_UP_GET_STARTED_OPTIONS = {
  OPEN_BUSSINESS: 'OpenBussiness',
  REGISTER_COMPANY: 'RegisterCompany',
  INVOICE_MANAGEMENT: 'InvoiceManagement'
};
