import React from 'react';

import BaseText from '../ui/typography/BaseText';
import '../../styles/pages/real-people-support.scss';
import CustomerSupport from '../../images/icons/contact-us-customer-support-chat.svg';
import TranslateBlue from '../../images/icons/translate.svg';
import XSText from '../ui/typography/XSText';
import IconSendEmailCoral from '../../images/icons/send-email-2-coral.svg';
import IconContactPhone from '../../images/icons/contact-phone.svg';
import IconMessagingWhatsApp from '../../images/icons/messaging-whatsapp.svg';
import config from '../../config/config.json';
import JonathanImg from '../../images/graphics/managerPeople/jonathan.webp';
import axel from '../../images/graphics/managerPeople/axel.webp';
import chalisa from '../../images/graphics/managerPeople/chalisa.webp';
import popo from '../../images/graphics/managerPeople/popo.webp';
import cher from '../../images/graphics/managerPeople/cher.webp';
import pear from '../../images/graphics/managerPeople/pear.webp';
import aaron from '../../images/graphics/managerPeople/aaron.webp';
import am from '../../images/graphics/managerPeople/am.webp';
import beatrice from '../../images/graphics/managerPeople/beatrice.webp';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

const supportPeople = [
  {
    image: 'jonathan',
    backgroundColor: 'bg-blue-200',
    imagePath: JonathanImg
  },
  {
    image: 'axel',
    backgroundColor: 'bg-blue-600',
    imagePath: axel
  },
  {
    image: 'chalisa',
    backgroundColor: 'bg-purple-200',
    imagePath: chalisa
  },
  {
    image: 'pear',
    backgroundColor: 'bg-yellow-500',
    imagePath: pear
  },
  {
    image: 'popo',
    backgroundColor: 'bg-coral-200',
    imagePath: popo
  },
  {
    image: 'cher',
    backgroundColor: 'bg-green-500',
    imagePath: cher
  },
  {
    image: 'aaron',
    backgroundColor: 'bg-yellow-500',
    imagePath: aaron
  },
  {
    image: 'am',
    backgroundColor: 'bg-blue-200',
    imagePath: am
  },
  {
    image: 'beatrice',
    backgroundColor: 'bg-blue-600',
    imagePath: beatrice
  }
];

const supportPoints = [
  {
    heading: 'Connect with us in any way you want',
    subHeading: `WhatsApp, WeChat, email, phone, we’ll get back to you no matter what.`,
    icon: CustomerSupport
  },
  {
    heading: 'We speak your language',
    subHeading: `English, Cantonese, Mandarin, \nFrench, Spanish, Hindi & Thai.`,
    icon: TranslateBlue
  }
];

export default function RealPeopleSupport() {
  return (
    <div
      className="flex md:flex-row md:gap-0 gap-8 faq-support-container flex-col md:justify-center items-center w-full"
      id="account-manager"
    >
      <div className="support-container">
        <div className="flex flex-wrap gap gap-4 relative md:justify-start justify-center z-10">
          {supportPeople?.map((people, index) => {
            return (
              <div
                className={`support-people-image flex items-end justify-center ${
                  (index === 0 || index === 6) && 'md:ml-24 ml-0'
                }  ${people?.backgroundColor}`}
                key={index}
              >
                <GatsbyImageWrapper
                  image={{
                    url: people?.imagePath,
                    width: 169.62,
                    height: 169.62
                  }}
                  className="w-full"
                  alt="support people"
                  loading="lazy"
                />
              </div>
            );
          })}
          <div className="formula-blur-bg"></div>
        </div>
      </div>

      <div className="support-text">
        <div className="pb-8 pt-2 flex flex-col justify-center h-full">
          <div>
            <BaseText
              title={`Can’t find \nyour answer?`}
              fontWeight="text-bold"
              textColor="text-blue-800"
              className="h2heading md:whitespace-pre-line"
            />
            {supportPoints?.map((point, index) => {
              return (
                <div className={`flex md:flex-row flex-col pt-6`} key={index}>
                  <img src={point?.icon} alt={'AccountingBills'} width="32" height="32" className="w-8 h-8 " />
                  <div className="md:ml-4 ml-0 md:my-auto my-2  flex flex-col">
                    <p className="support-point whitespace-pre-line">{point?.heading}</p>
                    <BaseText
                      title={point?.subHeading}
                      textColor="text-blue-800"
                      className="mt-2 md:whitespace-pre-line"
                    />
                  </div>
                </div>
              );
            })}
            <div className="flex border border-gray-400 rounded-lg mt-10">
              <a
                className="py-4 w-40 flex justify-center flex-col border-r border-gray-400 hover:no-underline"
                href={config.email_support_link}
              >
                <div>
                  <img src={IconSendEmailCoral} className="h-5 w-5 mb-2 mx-auto hover:no-underline" alt="send email" />
                  <XSText textAlign="text-center" title="Send us a message" />
                </div>
              </a>
              <a
                className="py-4 w-40 flex justify-center flex-col border-r border-gray-400 hover:no-underline"
                href={`tel:${config.phone_number}`}
              >
                <div>
                  <img src={IconContactPhone} className="h-5 w-5 mb-2 mx-auto" alt="phone call" />
                  <XSText
                    textAlign="text-center"
                    className="whitespace-pre-line"
                    title={'Call us at \n+852 5803 2818'}
                  />
                </div>
              </a>
              <a className="py-4 w-40 flex justify-center flex-col hover:no-underline" href={config.whatsapp_link}>
                <div>
                  <img src={IconMessagingWhatsApp} className="h-5 w-5 mb-2 mx-auto" alt="whats app call" />
                  <XSText textAlign="text-center" title="Connect with us on Whatsapp" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
